"use strict";

head = {
  userLogged: false
};
head.cookie = {
  get: function get(name) {
    var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  },
  set: function set(name, value, options) {
    options = options || {};
    var expires = options.expires;

    if (typeof expires == "number" && expires) {
      var date = new Date();
      date.setTime(date.getTime() + expires * 1000);
      expires = options.expires = date;
    }

    if (expires && expires.toUTCString) {
      options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);
    var updatedCookie = name + "=" + value;

    for (var propName in options) {
      if (options.hasOwnProperty(propName)) {
        updatedCookie += "; " + propName;
        var propValue = options[propName];

        if (propValue !== true) {
          updatedCookie += "=" + propValue;
        }
      }
    }

    document.cookie = updatedCookie;
  },
  del: function del(name) {
    set(name, "", {
      expires: -1
    });
  }
};
head.recaptcha = {
  isLoaded: false,
  render: function render() {
    $.each($('.g-recaptcha'), function (index, item) {
      window.grecaptcha.render(item, {
        'sitekey': '6LdYZAoTAAAAACEBPs5XjvbmfdtpI4hwdZaD6m1B'
      });
    });
  },
  loaded: function loaded() {
    this.isLoaded = true;
  }
};
head.func = {
  initOverlay: function initOverlay() {
    $('.close').click(function () {
      $(this).closest('.newOverlay').hide(0);
      return false;
    });
    $('.newoverlay-show').click(function () {
      $('.newOverlay').hide();
      $($(this).data('target')).show();
      return false;
    });
  }
};
head.user = {
  reloadUserInfo: function reloadUserInfo() {
    $.ajax({
      url: '/account/renderuserinfo',
      dataType: 'html',
      success: function success(data) {
        $('#userinfo.account').html(data);
        pt.userLogged = true;
        pt.func.initOverlay();
      }
    });
  }
};
head.mobileMenu = {
  mainButton: $('#js-m-show-menu'),
  mainMenu: $('#m-main-menu'),
  mainLinks: $('#m-main-menu').find('a.main-link'),
  subMenu: $('#m-sub-menu'),
  wrapperMobile: $('#m-wrapper'),
  topButton: $('.m-footer__top'),
  mtabs: $('#mtabs'),
  mtabsShow: $('.m-show-tabs'),
  mtabsClose: $('.m-block-close'),
  mMenuCategory: $('#mMenuCategory'),
  mMenuCategoryShow: $('.m-menu-category-show'),
  init: function init() {
    var me = this;
    $(document).on('click', '.subclose', function () {
      me.mainMenu.removeClass('show');
      me.subMenu.removeClass('show').addClass('hide').empty();
      me.wrapperMobile.removeClass('hide');
      return false;
    });
    $(document).on('click', '.subback', function () {
      var parent = $(this).parent().parent();

      if ($(parent).siblings().length > 0) {
        $(parent).removeClass('show').addClass('hide');
        $(parent).prev().addClass('show');
        $(parent).remove();
      } else {
        me.mainMenu.addClass('show');
        me.subMenu.removeClass('show').addClass('hide').empty();
      }

      return false;
    });
    me.mainButton.bind('click', function () {
      if (me.wrapperMobile.hasClass('hide') && me.mainMenu.hasClass('show')) {
        me.mainMenu.removeClass('show');
        me.subMenu.removeClass('show').addClass('hide').empty();
        me.wrapperMobile.removeClass('hide');
        return false;
      }

      me.wrapperMobile.addClass('hide');

      if ($('.m-choice').hasClass('show')) {
        $('.m-choice').removeClass('show');
      }

      if (me.mMenuCategory.length > 0) {
        me.mMenuCategory.removeClass('show');
      }

      if (me.mtabs.length > 0) {
        me.mtabs.removeClass('show');
      }

      me.mainMenu.addClass('show');
      return false;
    });
    me.mainLinks.bind('click', function () {
      var url = $(this).attr('data-url');
      me.getMenuItems(url);
      me.subMenu.empty();
      return false;
    });
    me.topButton.bind('click', function () {
      $(window).scrollTo(0, 500);
      return false;
    });
    $(document).on('click', '.sublink', function () {
      var url = $(this).attr('data-url');
      me.getMenuItems(url);
      return false;
    });
    me.mtabsClose.bind('click', function () {
      me.mtabsClose.parent().parent().removeClass('show');
      me.wrapperMobile.removeClass('hide');
      return false;
    });
    me.mtabsShow.bind('click', function () {
      me.wrapperMobile.addClass('hide');
      me.mtabs.addClass('show');
      return false;
    });
    me.mMenuCategoryShow.bind('click', function () {
      me.wrapperMobile.addClass('hide');
      me.mMenuCategory.addClass('show');
      return false;
    });
  },
  getMenuItems: function getMenuItems(url) {
    var me = this;
    $.ajax({
      url: '/getsubmenu',
      data: {
        query: url
      },
      dataType: 'json',
      success: function success(data) {
        $($.templates("#subTmpl").render(data)).appendTo(me.subMenu);
        $('#submenu_' + data.Url).addClass('show');
        me.mainMenu.removeClass('show');
        $('#submenu_' + data.Url).siblings().removeClass('show');
        me.subMenu.removeClass('hide').addClass('show');
        $(document).scrollTop(0);
      }
    });
  }
};
$(function () {
  $(document).on('userLoggedIn', function () {
    head.user.reloadUserInfo();
    head.cookie.set('dontshowsubscribepopup', true);

    if (head.comment.needAddCommentAfterLogin) {
      head.comment.addComment(function () {
        head.comment.needAddCommentAfterLogin = false;
      });
    }
  });
  head.func.initOverlay();
  head.mobileMenu.init();
  var overlay = $('.js-m-overlay'),
      header = $('.header'),
      topButton = $('.top-page-button'),
      search = $('.header__search-input');
  search.focus(function () {
    if ($(document).width() > 740) {
      return false;
    }

    header.addClass('upper');
    overlay.show();
  });
  search.blur(function () {
    if ($(document).width() > 740) {
      return false;
    }

    header.removeClass('upper');
    overlay.hide();
  });
  $(window).scroll(function () {
    if ($(window).scrollTop() > 300 && topButton.length > 0) {
      topButton.fadeIn('slow');
    } else {
      if (topButton.length > 0) {
        topButton.fadeOut('slow');
      }
    }
  });
  topButton.bind('click', function () {
    $(window).scrollTo(0, 500);
    return false;
  });
});

$.fn.delayKeyup = function (callback, ms) {
  var timer = 0;
  var el = $(this);
  $(this).keyup(function () {
    clearTimeout(timer);
    timer = setTimeout(function () {
      callback(el);
    }, ms);
  });
  return $(this);
};