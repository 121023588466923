"use strict";

// arrows
var menuArrows = ["\n    <svg class=\"icon icon-arrow-menu\">\n        <use xlink:href=\"/img/sprite.svg#icon-arrow-menu\"></use>\n    </svg>", "<svg class=\"icon icon-arrow-menu\">\n        <use xlink:href=\"/img/sprite.svg#icon-arrow-menu\"></use>\n    </svg>"];
var defArrows = ["\n    <svg class=\"icon icon-left\">\n        <use xlink:href=\"/img/sprite.svg#icon-left\"></use>\n    </svg>", "<svg class=\"icon icon-right\">\n        <use xlink:href=\"/img/sprite.svg#icon-right\"></use>\n    </svg>"];

function createCarousel(wrap, arrows) {
  wrap.owlCarousel({
    items: 1,
    nav: true,
    navText: arrows,
    navElement: 'button',
    dots: false
  });
}

function render(items, wrap, drop, dropGroup) {
  var wrapWidth = wrap.width();
  var sum = 0,
      i = 0;
  wrap.find('.owl-nav').remove();

  for (var index = 0; index < items.length; index++) {
    var _this = $(items[index]);

    _this.attr('data-count', index);

    _this.attr('data-group', i);

    sum += _this.width();

    if (sum >= wrapWidth) {
      _this.attr('data-group', i + 1);

      sum = 0;
      sum += _this.width();
      $('[data-group="' + i + '"]').wrapAll('<div class="menu__group js-menu-group"></div>');
      i++;
    }
  }

  $('[data-group="' + i + '"]').wrapAll('<div class="menu__group js-menu-group"></div>');
  createCarousel(wrap, menuArrows);
  items.each(function (i, el) {
    var item = $(el);
    item.mouseenter(function (e) {
      var index = item.data('count');
      items.removeClass('active');
      item.addClass('active');
      drop.show();
      dropGroup.hide();
      dropGroup.eq(index).show();
    });
  });
  $('.owl-nav button').click(function (e) {
    items.removeClass('active');
    drop.hide();
  });
} // menu


$(function () {
  var menu = $('.js-menu');
  var wrap = menu.find('.js-menu-wrap');
  var drop = menu.find('.js-menu-drop'),
      dropGroup = menu.find('.js-menu-drop-group');
  var items = $('.js-menu-item');
  $(window).on('resize', function () {
    wrap.trigger('destroy.owl.carousel');
    wrap.removeClass('owl-loaded');
    wrap.empty();

    for (var i = 0; i < items.length; i++) {
      var item = $(items[i]);
      item.removeAttr('data-group');
      wrap.append(item);
    }

    render(items, wrap, drop, dropGroup);
  });
  $(window).on('load', function () {
    render(items, wrap, drop, dropGroup);
  });
  menu.mouseleave(function () {
    items.removeClass('active');
    drop.hide();
  });
}); // carousel arrows

var carouselArrows = ["\n    <svg class=\"icon icon-prev\">\n        <use xlink:href=\"/img/sprite.svg#icon-prev\"></use>\n    </svg>\n    <svg class=\"icon icon-prev-hover\">\n        <use xlink:href=\"/img/sprite.svg#icon-prev-hover\"></use>\n    </svg>", "<svg class=\"icon icon-next\">\n        <use xlink:href=\"/img/sprite.svg#icon-next\"></use>\n    </svg>\n    <svg class=\"icon icon-next-hover\">\n        <use xlink:href=\"/img/sprite.svg#icon-next-hover\"></use>\n    </svg>"]; // promo slider

$(function () {
  var promo = $('.js-promo');

  if (promo.length) {
    promo.owlCarousel({
      loop: true,
      nav: false,
      autoplay: true,
      autoplayTimeout: 5000,
      smartSpeed: 1000,
      lazyLoad: true,
      responsive: {
        0: {
          items: 1,
          merge: false
        },
        741: {
          items: 2,
          merge: true,
          slideBy: 1
        }
      }
    });
    promo.children('.owl-dots').removeClass('disabled');
    promo.on('dragged.owl.carousel', function (e) {
      promo.children('.owl-dots').removeClass('disabled');
    });
    promo.on('changed.owl.carousel', function (e) {
      promo.children('.owl-dots').removeClass('disabled');
    });
  }
}); // categories slider

$(function () {
  var categories = $('.js-categories');

  if (categories.length) {
    categories.owlCarousel({
      loop: true,
      nav: true,
      navText: carouselArrows,
      navElement: 'button',
      dots: false,
      responsive: {
        0: {
          items: 1,
          nav: false,
          autoWidth: true
        },
        320: {
          items: 2,
          nav: false,
          autoWidth: false
        },
        412: {
          items: 3,
          nav: false,
          autoWidth: false
        },
        768: {
          items: 4,
          nav: true,
          autoWidth: false
        },
        1024: {
          items: 5,
          nav: true,
          autoWidth: false
        },
        1260: {
          items: 6,
          nav: true,
          autoWidth: false
        }
      }
    });
  }
}); // for svg sprite

$(function () {
  if (svg4everybody) {
    svg4everybody();
  }
});
var slider;

function initGallery() {
  if (slider) {
    $('.js-gallery .owl-stage-outer').detach();
    slider.data('owl.carousel').destroy();
  }

  var gallery = $('.js-gallery');

  if (gallery.length) {
    var thumb = gallery.find('.js-gallery-thumb'),
        item = gallery.find('.js-gallery-item'),
        square = gallery.find('.js-gallery-square'),
        pic = gallery.find('.js-gallery-pic');
    initCarousel();
    initZoom();
  }

  function initCarousel() {
    slider = gallery.find('.js-gallery-slider');
    slider.owlCarousel({
      items: 1,
      nav: false,
      navText: defArrows,
      navElement: 'button',
      dots: true,
      responsive: {
        1024: {
          nav: true,
          dots: false
        }
      }
    });
    slider.on('changed.owl.carousel', function (event) {
      thumb.removeClass('active');
      thumb.eq(event.item.index).addClass('active');
    });
    thumb.on('click', function (e) {
      e.preventDefault();
      slider.trigger('to.owl.carousel', $(this).index());
    });
    thumb.on('mouseenter', function (e) {
      e.preventDefault();
      slider.trigger('to.owl.carousel', $(this).index());
    });
  }

  function initZoom() {
    item.on('mousemove', function (e) {
      var _this = $(this),
          itemHeight = _this.height(),
          squareHeight = square.height(),
          maxTop = itemHeight - squareHeight,
          y = e.pageY - _this.offset().top,
          newY = y - squareHeight / 2,
          percent = y / itemHeight * 100;

      pic.attr('src', _this.data('pic'));
      pic.css('transform', 'translateY(-' + percent / 2 + '%)');

      if (newY >= 0) {
        if (newY >= maxTop) {
          square.css('top', maxTop + 'px');
        } else {
          square.css('top', newY + 'px');
        }
      } else {
        square.css('top', 0 + 'px');
      }
    });
    item.hover(function () {
      gallery.addClass('visible');
    }, function () {
      gallery.removeClass('visible');
    });
  }
}