"use strict";

function up() {
  var counter = $(".header__counter")[0];
  if (!counter) return;
  var value = +counter.innerHTML;
  counter.innerHTML = value + 1;
}

function down() {
  var counter = $(".header__counter")[0];
  if (!counter) return;
  var value = +counter.innerHTML;
  counter.innerHTML = value - 1;
}

function remove(count) {
  var counter = $(".header__counter")[0];
  if (!counter) return;
  var value = +counter.innerHTML;
  counter.innerHTML = value - count;
}

function setValue(value) {
  var counter = $(".header__counter")[0];
  if (!counter) return;
  counter.innerHTML = value;
}