"use strict";

var cartFormRule = {
  required: "Обязательно для заполения"
};
var cartForm = {
  sendButton: $("#sendOrderButton"),
  form: $("#cartForm"),
  formRules: {
    address: {
      required: true,
      protectSpace: true,
      regex: /^.{0,200}$/
    },
    office: {
      required: true,
      protectSpace: true,
      regex: /^.{0,10}$/
    },
    email: {
      required: true,
      email: true
    },
    phone: {
      required: true,
      regex: /^8\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/
    },
    name: {
      required: true,
      protectSpace: true,
      regex: /^.{0,30}$/
    },
    comment: {
      protectSpace: true,
      regex: /^.{0,100}$/
    }
  },
  formMessages: {
    address: {
      required: cartFormRule.required,
      regex: "Максимум 200 символов"
    },
    office: {
      required: cartFormRule.required,
      regex: "Максимум 10 символов"
    },
    email: {
      required: cartFormRule.required,
      email: "Неверный формат, пример ivan@product-test.ru"
    },
    phone: {
      required: cartFormRule.required,
      regex: "Заполните номер телефона"
    },
    name: {
      required: cartFormRule.required,
      regex: "Максимум 30 символов"
    },
    comment: {
      regex: "Максимум 100 символов"
    }
  },
  formMask: {
    phone: "8 (999) 999-99-99"
  },
  formData: {
    address: null
  },
  init: function init() {
    this.initMask();
    this.initValidatorMethods();
    this.validate();
    this.initAutocomplete();
    var me = this;
    me.sendButton.click(function () {
      me.form.submit();
    });
    this.form.find('input').on('keyup', function () {
      if (me.form.valid()) me.sendButton.addClass("sand-order-button_active");else me.sendButton.removeClass("sand-order-button_active");
    });
  },
  initMask: function initMask() {
    this.form.find('input[name="phone"]').inputmask(this.formMask.phone);
  },
  initAutocomplete: function initAutocomplete() {
    var me = this;
    var autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {
      types: ['address'],
      componentRestrictions: {
        country: 'ru'
      }
    });
    autocomplete.addListener('place_changed', function () {
      var place = autocomplete.getPlace();
      me.formData["address"] = place.formatted_address;
    });
  },
  initValidatorMethods: function initValidatorMethods() {
    $.validator.addMethod("regex", function (value, element, regexp) {
      return regexp.test(value);
    }, "Неверный формат");
    $.validator.addMethod("protectSpace", function (value) {
      return !/^[\s\t]+$/.test(value);
    }, cartFormRule.required);
  },
  validate: function validate() {
    this.form.validate({
      rules: this.formRules,
      messages: this.formMessages,
      submitHandler: function submitHandler() {
        alert("Submitted!");
      }
    });
  }
};
var cart = {
  prices: $('.product-amount__price'),
  totalPrice: 0,
  price: 12400,
  init: function init() {
    this.initCounter(1);
    cartForm.init();
  },
  initCounter: function initCounter(count) {
    var me = this;
    var counter = $('.counter__count');
    counter.val(count);
    $('.counter__up').on('click', function () {
      var val = +counter.val() + 1;
      counter.val(val);
      me.updatePrice(val);
    });
    $('.counter__down').on('click', function () {
      var val = +counter.val() - 1;
      if (val < 1) val = 1;
      counter.val(val);
      me.updatePrice(val);
    });
  },
  updatePrice: function updatePrice(count) {
    var price = count * this.price;
    var text = numeral(price).format('0,0').replace(',', ' ');
    this.prices.text(text);
    pt.cookie.set('count', count, {
      path: "/",
      expires: 100000000
    });
    pt.cookie.set('price', price, {
      path: "/",
      expires: 100000000
    });
  }
};